import React from 'react';
import { contestImagesGreen, contestImagesWhite } from '../../utils/constants';
import Slider from "react-slick";
import ContestSliderArrow from '../ContestSliderArrow/ContestSliderArrow';
import rememberImage from '../../images/light/not-for-sale/Remember-min.webp';
import grandImage from '../../images/light/not-for-sale/Grand-min.webp';
import { contestPopupImages } from '../../utils/constants';

function Contest({ setContestImage }) {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

  };

  return (
    <div className="contest">

      {/* green container */}
      <div className="contest__container">

        <Slider
          {...settings}
          nextArrow={
            <ContestSliderArrow arrowType='right'/>
          }
          prevArrow={
            <ContestSliderArrow arrowType='left'/>
          }
        >
          {contestImagesGreen.map((i) => (
            <img
              src={i.src}
              alt="contest picture"
              key={i.number}
            />
          ))}
        </Slider>

        <div className="contest__text-box" onClick={() => setContestImage(contestPopupImages[0])}>
          <span className="contest__text contest__text-roteted">project</span>
          <span className="contest__text">Remember</span>
        </div>

      </div>

      {/* white container */}
      <div className="contest__container">

        <Slider
          {...settings}
          nextArrow={
            <ContestSliderArrow arrowType='right' btnClass="contest__slider-btn_dark"/>
          }
          prevArrow={
            <ContestSliderArrow arrowType='left' btnClass="contest__slider-btn_dark"/>
          }
        >
          {contestImagesWhite.map((i) => (
            <img
              src={i.src}
              alt="contest picture"
              key={i.number}
              className="contest__img"
            />
          ))}
        </Slider>

        <div className="contest__text-box contest__text-box_dark" onClick={() => setContestImage(contestPopupImages[1])}>
          <span className="contest__text contest__text-roteted">project</span>
          <span className="contest__text">
            My<br/>
            grand<br/>
            women
          </span>
        </div>

      </div>


    </div>
  )
}

export default Contest;
