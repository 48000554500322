import React from 'react';
import { IoCloseOutline } from 'react-icons/io5';

function ImagePopup({ image, onClose }) {
  return (
    <div className={`image-popup ${image ? 'image-popup_opened' : ''}`}>
      <div className='image-popup__container'>
        <button type='button' className='image-popup__close-btn' onClick={onClose}>
          <IoCloseOutline />
        </button>
        <img src={image?.src} className='image-popup__img'></img>
        <p className='image-popup__text'>
          {image?.text}
        </p>
      </div>
    </div>
  )
}

export default ImagePopup;
