import React from 'react';
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";

function ContestSliderArrow(props) {
  const { onClick, arrowType, btnClass } = props;
  return (
    <button
      type="button"
      className={`contest-slider-arrow${arrowType === 'left' ? ' contest-slider-arrow_left' : ''} ${btnClass || ''}`}
      onClick={onClick}
      onKeyDown={onClick}
    >
      {arrowType === 'left' ? <SlArrowLeft /> : <SlArrowRight />}
    </button>
  );
}

export default ContestSliderArrow;
