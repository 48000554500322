import React from 'react';

function Contacts(props) {
  return (
    <div className="contacts">
      <a
        href="mailto:nsstafeeva@gmail.com"
        className={`contacts__link${props.activeLinks?.includes('email') ? ' contacts__link_active' : ''}`}
        title="Sent email"
        target="_blank"
        rel="noreferrer"
      >
        <svg id="_Слой_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 312.83 312.83" className="contacts__img">
          <path d="m156.41,312.83c-41.61,0-80.85-16.32-110.47-45.94C16.32,237.26,0,198.03,0,156.41S16.32,75.57,45.94,45.94C75.57,16.32,114.8,0,156.41,0s80.85,16.32,110.47,45.94c29.63,29.63,45.94,68.86,45.94,110.47s-16.32,80.85-45.94,110.47-68.86,45.94-110.47,45.94Zm0-301.81c-38.67,0-75.14,15.17-102.68,42.72S11.01,117.74,11.01,156.41s15.17,75.14,42.72,102.68,64.01,42.72,102.68,42.72,75.14-15.17,102.68-42.72,42.72-64.01,42.72-102.68-15.17-75.14-42.72-102.68S195.08,11.01,156.41,11.01Z" />
          <path d="m235.11,90.12H77.72c-16.71,0-30.3,13.59-30.3,30.31v79.98c0,16.71,13.59,30.31,30.3,30.31h157.39c16.71,0,30.3-13.59,30.3-30.31v-79.98c0-16.71-13.59-30.31-30.3-30.31Zm-64.58,74.67c-6.02,5.76-15.32,6-21.62.54l-77.24-66.78c1.99-.56,4.02-.84,6.05-.84h157.39c1.45,0,2.93.16,4.43.46-8.51,8.53-33.45,32.6-69.01,66.61Zm-43.37-5.79l15.58,13.47c4.66,4.03,10.58,6.25,16.68,6.25,6.57,0,12.83-2.52,17.62-7.11l11.64-11.14,53.15,61.61c-2.2.69-4.46,1.04-6.72,1.04H77.72c-3.84,0-7.63-.99-11.02-2.89l60.46-61.23Zm68.34-5.07c8.97-8.6,18.55-17.81,27.13-26.09,8.31-8.02,14.8-14.33,19.3-18.76,3.32-3.26,5.39-5.32,6.78-6.81,5.72,4.29,9.11,11.03,9.11,18.16v79.98c0,6.52-2.76,12.61-7.62,16.93l-54.7-63.4Zm-75.48-1.12l-60.44,61.21c-2.95-3.92-4.57-8.73-4.57-13.63v-79.98c0-6.57,2.83-12.77,7.78-17.09l57.23,49.48Z" />
        </svg>
        <span className={`contacts__name${props.activeName ? ' contacts__name_active' : ''}`}>nsstafeeva@gmail.com</span>
      </a>

      <a
        href="https://t.me/nsstafeeva"
        className={`contacts__link${props.activeLinks?.includes('telegram') ? ' contacts__link_active' : ''}`}
        title="Telegram"
        target="_blank"
        rel="noreferrer"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142" className="contacts__img">
          <path d="m74.44,142h-6.88C30.31,142,0,111.69,0,74.44v-6.88C0,30.31,30.31,0,67.56,0h6.88c37.25,0,67.56,30.31,67.56,67.56v6.88c0,37.25-30.31,67.56-67.56,67.56ZM67.56,5C33.06,5,5,33.06,5,67.56v6.88c0,34.5,28.06,62.56,62.56,62.56h6.88c34.5,0,62.56-28.06,62.56-62.56v-6.88c0-34.5-28.06-62.56-62.56-62.56h-6.88Z" />
          <path d="m99.73,37.87c-2.29-1.62-5.18-1.9-7.73-.73l-65.84,30.14c-3,1.38-4.77,4.39-4.51,7.68s2.49,5.98,5.66,6.87l23.63,6.56,34.26,23.12c1.32.89,2.82,1.34,4.34,1.34,1.13,0,2.26-.25,3.33-.76,2.5-1.19,4.15-3.54,4.41-6.3l5.68-60.88c.26-2.79-.95-5.43-3.24-7.06Zm-1.97,6.57l-5.68,60.88c-.12,1.3-1.05,1.87-1.44,2.06-.39.19-1.42.54-2.51-.19l-29.84-20.14c.13-.11.27-.23.4-.36l22.3-23.86c2.16-2.34-.99-5.8-3.52-3.86,0,0-25.79,20.05-25.79,20.05-1.29,1.1-1.9,2.36-2.02,3.6l-20.96-5.82c-1.59-.44-1.82-1.83-1.85-2.24-.03-.42-.02-1.83,1.48-2.51l65.84-30.14c1.2-.55,2.17-.02,2.53.24.36.25,1.18.99,1.06,2.31Z" />
        </svg>
        <span className={`contacts__name${props.activeName ? ' contacts__name_active' : ''}`}>@nsstafeeva</span>
      </a>

      <a
        href="https://www.instagram.com/natalyastafeeva/"
        className={`contacts__link${props.activeLinks?.includes('instagram') ? ' contacts__link_active' : ''}`}
        title="Instagram"
        target="_blank"
        rel="noreferrer"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142" className="contacts__img">
          <path d="m119.48,142H22.52c-12.42,0-22.52-10.1-22.52-22.52V22.52C0,10.1,10.1,0,22.52,0h96.95c12.42,0,22.52,10.1,22.52,22.52v96.95c0,12.42-10.1,22.52-22.52,22.52ZM22.52,5c-9.66,0-17.52,7.86-17.52,17.52v96.95c0,9.66,7.86,17.52,17.52,17.52h96.95c9.66,0,17.52-7.86,17.52-17.52V22.52c0-9.66-7.86-17.52-17.52-17.52H22.52Z" />
          <path d="m71,109.5c-21.23,0-38.5-17.27-38.5-38.5s17.27-38.5,38.5-38.5,38.5,17.27,38.5,38.5-17.27,38.5-38.5,38.5Zm0-70c-17.37,0-31.5,14.13-31.5,31.5s14.13,31.5,31.5,31.5,31.5-14.13,31.5-31.5-14.13-31.5-31.5-31.5Z" />
          <path d="m113.25,40.75c-5.24,0-9.5-4.26-9.5-9.5s4.26-9.5,9.5-9.5,9.5,4.26,9.5,9.5-4.26,9.5-9.5,9.5Zm0-13c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5,3.5-1.57,3.5-3.5-1.57-3.5-3.5-3.5Z" />
        </svg>
      </a>

      <a
        href="https://www.behance.net/natalyastafeeva/projects"
        className={`contacts__link${props.activeLinks?.includes('behance') ? ' contacts__link_active' : ''}`}
        title="Behance"
        target="_blank"
        rel="noreferrer"
      >
        <svg id="_Слой_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 142" className="contacts__img">
          <path d="m119.48,142H22.52c-12.42,0-22.52-10.1-22.52-22.52V22.53C0,10.1,10.1,0,22.52,0h96.95c12.42,0,22.52,10.1,22.52,22.53v96.95c0,12.42-10.1,22.52-22.52,22.52ZM22.52,5c-9.66,0-17.52,7.86-17.52,17.53v96.95c0,9.66,7.86,17.52,17.52,17.52h96.95c9.66,0,17.52-7.86,17.52-17.52V22.53c0-9.66-7.86-17.53-17.52-17.53H22.52Z" />
          <path d="m48.16,36.7c3.15,0,6.05.24,8.72.96,2.67.48,4.85,1.47,6.8,2.67s3.39,2.91,4.38,5.09c.96,2.19,1.47,4.85,1.47,7.76,0,3.39-.72,6.29-2.43,8.48-1.47,2.19-3.87,4.14-6.8,5.57,4.14,1.2,7.28,3.39,9.2,6.29,1.92,2.91,3.15,6.53,3.15,10.67,0,3.39-.72,6.29-1.95,8.72-1.2,2.43-3.15,4.62-5.33,6.05-2.19,1.47-4.85,2.67-7.76,3.39-2.91.72-5.81,1.2-8.72,1.2l-32.49.06V36.7h31.77Zm-1.95,27.15c2.67,0,4.85-.72,6.53-1.95s2.43-3.39,2.43-6.05c0-1.47-.24-2.91-.72-3.87-.48-.96-1.2-1.71-2.19-2.43-.96-.48-1.95-.96-3.15-1.2s-2.43-.24-3.87-.24h-14.06v15.76s15.01-.03,15.01-.03Zm.72,28.62c1.47,0,2.91-.24,4.14-.48,1.2-.24,2.43-.72,3.39-1.47s1.71-1.47,2.43-2.67c.48-1.2.96-2.67.96-4.38,0-3.39-.96-5.81-2.91-7.52-1.95-1.47-4.62-2.19-7.76-2.19h-15.97v18.67h15.73v.03Z" />
          <path d="m93.74,92.23c1.95,1.95,4.85,2.91,8.72,2.91,2.67,0,5.09-.72,7.04-1.95,1.95-1.47,3.15-2.91,3.63-4.38h11.87c-1.95,5.81-4.85,9.95-8.72,12.62-3.87,2.43-8.48,3.87-14.06,3.87-3.87,0-7.28-.72-10.43-1.95-3.15-1.2-5.57-2.91-7.76-5.33-2.19-2.19-3.87-4.85-4.85-8-1.2-3.15-1.71-6.53-1.71-10.43,0-3.63.48-7.04,1.71-10.19s2.91-5.81,5.09-8.24c2.19-2.19,4.85-4.14,7.76-5.33,3.15-1.2,6.29-1.95,10.19-1.95,4.14,0,7.76.72,10.91,2.43,3.15,1.71,5.57,3.63,7.52,6.53,1.95,2.67,3.39,5.81,4.38,9.2.48,3.39.72,6.8.48,10.67h-35.15c0,3.96,1.44,7.58,3.39,9.53Zm15.28-25.47c-1.71-1.71-4.38-2.67-7.52-2.67-2.19,0-3.87.48-5.33,1.2s-2.43,1.71-3.39,2.67c-.96.96-1.47,2.19-1.71,3.39s-.48,2.19-.48,3.15h21.82c-.48-3.6-1.71-6.02-3.39-7.73Zm-21.34-25.44h27.15v6.53h-27.15v-6.53Z" />
        </svg>
      </a>

      <a
        href="https://stock.adobe.com/ru/contributor/209632961/stafeeva"
        className={`contacts__link ${props.activeLinks?.includes('adobe') ? ' contacts__link_active' : ''}`}
        title="Adobe stock"
        target="_blank"
        rel="noreferrer"
      >
        <svg id="_Слой_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 323.39 323.39" className="contacts__img">
          <path d="m297.67,323.39H25.72c-14.18,0-25.72-11.54-25.72-25.72V25.72C0,11.54,11.54,0,25.72,0h271.95c14.18,0,25.72,11.54,25.72,25.72v271.95c0,14.18-11.54,25.72-25.72,25.72ZM25.72,11.39c-7.9,0-14.33,6.43-14.33,14.33v271.95c0,7.9,6.43,14.33,14.33,14.33h271.95c7.9,0,14.33-6.43,14.33-14.33V25.72c0-7.9-6.43-14.33-14.33-14.33H25.72Z" />
          <path d="m122.45,144.63l-1.72-.87c-7.84-3.94-15.25-7.67-21.09-13.35-4.24-4.12-5.92-9.68-4.48-14.87,1.47-5.3,5.89-9.29,11.85-10.69,5.23-1.23,10.99-1.31,15.9-1.23,8.9.14,16.59,1.76,23.52,4.94,1.16.53,2.37.99,3.8,1.54.66.25,1.37.52,2.16.82l5.79,2.27v-6.22c0-2.32-.03-4.57-.05-6.77-.06-4.95-.11-9.63.15-14.23.36-6.25-2.49-10.11-8.71-11.8-7.6-2.06-15.06-3.33-22.8-3.91-14.01-1.03-25.46.23-36.02,3.96-24.7,8.74-37.9,33.8-31.37,59.61,3.52,13.92,12.59,24.87,28.55,34.46,4.14,2.49,8.42,4.84,12.56,7.11,4.92,2.7,10.02,5.5,14.79,8.49,8.29,5.19,12.01,10.04,12.42,16.21.36,5.37-.87,9.08-3.98,12.05-4.29,4.08-9.85,4.6-16.67,4.85-12.91.45-42.87-9.81-43.17-9.91l-5.82-2.01,1.27,39.5,2.94.87c.79.23,19.34,5.69,28.61,6.06,1.74.27,4.6.47,8.2.47,12.76,0,34.76-2.53,48.63-13.5,11.75-8.84,17.72-20.98,17.76-36.07,0-3.14-1.28-21.82-7.12-29.57-8.97-13.84-23.21-21.82-35.87-28.21Z" />
          <path d="m239.28,74.16v38.3h24.75v38.69h-24.75v48.85c0,5.88.48,9.76,1.44,11.66,1.47,2.93,4.05,4.4,7.74,4.4,3.32,0,11.26-.38,16.91-.37l.32,33.48c-11.12,2.86-21.51,4.28-31.16,4.28-11.2,0-19.45-1.69-24.75-5.05-5.3-3.38-9.23-8.49-11.77-15.37-2.54-6.87-3.81-17.99-3.81-33.39v-48.49h-16.58v-38.69h16.58v-25.32l45.08-12.98Z" />
        </svg>
      </a>

      <a
        href="https://www.shutterstock.com/g/Stafeeva+Natalya"
        className={`contacts__link ${props.activeLinks?.includes('shutter') ? ' contacts__link_active' : ''}`}
        title="Shutterstock"
        target="_blank"
        rel="noreferrer"
      >
        <svg id="_Слой_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 323.39 323.39" className="contacts__img">
          <path d="m297.67,323.39H25.72c-14.18,0-25.72-11.54-25.72-25.72V25.72C0,11.54,11.54,0,25.72,0h271.95c14.18,0,25.72,11.54,25.72,25.72v271.95c0,14.18-11.54,25.72-25.72,25.72ZM25.72,11.39c-7.9,0-14.33,6.43-14.33,14.33v271.95c0,7.9,6.43,14.33,14.33,14.33h271.95c7.9,0,14.33-6.43,14.33-14.33V25.72c0-7.9-6.43-14.33-14.33-14.33H25.72Z" />
          <path d="m86.54,152.58h-45.55v-50.12c0-35.15,28.6-63.75,63.75-63.75h41.01v45.55h-41.01c-10.04,0-18.21,8.17-18.21,18.21v50.12Z" />
          <path d="m236.85,170.8h45.55v50.12c0,35.15-28.6,63.75-63.75,63.75h-41.01v-45.55h41.01c10.04,0,18.21-8.17,18.21-18.21v-50.12Z" />
        </svg>
      </a>

    </div>
  );
}

export default Contacts;
